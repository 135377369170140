body {
  margin: 0 !important;
}

.main-login-box {
  margin: auto;
  width: 100%;
  max-width: 1200px;
  padding-top: 5%;
}

.main-login-box > .col-high {
  min-height: 70vh;
}

.main-login-box > .col-high > .left-style {
  background-image: url('../assets/images/login-card.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 60px;
}

.inner-left,
.inner-right {
  margin: 11% auto auto 11%;
  width: 75%;
}

.inner-left-footer,
.inner-right-layout {
  margin: auto auto auto 11%;
  width: 75%;
}

.inner-left > .ant-col > h2 {
  color: #ffffff !important;
  margin-top: 15%;
}

.inner-left > .ant-col > .left-detail {
  color: #e3d1ff;
  font-size: small;
}

.footer-logo {
  width: 100%;
}

.footer-icon {
  margin-top: -3px;
  margin-left: 1rem;
  width: 2.81rem;
  height: auto;
}

.footer-connect-us {
  padding: 4px 0;
  background: transparent;
  color: #fff;
  font-size: 1.5rem;
}
.telegram-icon {
  max-width: 24px;
}

.header-icon {
  margin-top: -3px;
  margin-left: 1rem;
  width: 2.44rem;
  height: auto;
}

.claim-credits-link {
  color: #b492ff;
  cursor: pointer;
}

.claim-credits-link:hover {
  text-decoration: underline;
}

.popup-custom-two .ant-modal {
  width: 90%;
  max-width: 540px;
}

.popup-content {
  color: #fff;
}

.popup-content .ant-col {
  margin-left: auto;
}

.main-login-box > .col-high > .right-style {
  background-color: #0a0013;
}

.login-button {
  background-image: linear-gradient(45deg, #8a3ea7, #5e3ea6);
  border-radius: 20px;
}

.password-input > input,
.password-input > input:-webkit-autofill:focus,
.password-input > input:-webkit-autofill:active,
.password-input > input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #25202d inset !important;
  -webkit-text-fill-color: #ffffff;
  background-color: #25202d !important;
  color: #ffffff;
}

.form-control {
  -webkit-text-fill-color: #ffffff;
  background-color: #25202d !important;
  color: #ffffff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #25202d inset !important;
  -webkit-text-fill-color: #ffffff;
}

.form-input-style {
  border: 2px solid #332356;
  background-color: #25202d;
  border-radius: 0;
  color: #ffffff;
}

.ant-form-item .ant-select {
  border: 2px solid #332356;
  background-color: #25202d;
  border-radius: 0;
  color: #ffffff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
  border: none;
  z-index: 1;
}

.ant-modal-content {
  background-color: #0b0013;
  border: 1px solid #340750;
}

.ant-modal-header {
  background: #0b0013;
  border-bottom: none;
}

.ant-modal-title {
  color: #fcfffb;
  margin: 24px auto;
  font-weight: bold;
  font-size: 1.5rem;
}

.ant-modal-confirm-title {
  color: antiquewhite !important;
  font-weight: bold;
  text-align: center;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  text-align: center;
}

.ant-modal-confirm-btns button:last-child {
  background-image: linear-gradient(45deg, #8a3ea7, #5e3ea6);
  border: none;
  border-radius: 20px;
  width: 25%;
}

.ant-modal-confirm-btns button:first-child {
  background-image: linear-gradient(45deg, #2f2f2f, #0f0f0f);
  border: none;
  border-radius: 20px;
  width: 25%;
  color: #e1d2f7;
}

.ant-select-dropdown {
  background-color: #1d1e20;
}

.ant-select-item {
  color: #e4e4e4;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: #000000;
  font-weight: bold;
}

.validate-popup-content {
  color: #e1d2f7;
  margin-left: 5%;
  width: 85%;
}

.close-model-icon {
  color: #5c3ea1;
  font-weight: bold;
  font-size: 2.5rem;
  margin: 30% 30% auto auto;
}

.container-form-auth {
  padding: 50px 60px;
  background-color: #0a0013;
}

.container-form-auth__title {
  color: #ffffff !important;
  margin-top: 15%;
}

.container-form-auth__infor {
  color: #e3d1ff;
  font-size: small;
}

.btn-custom-Line {
  width: max-content;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.lobby-body-bg {
  background-image: url('../assets/images/lobby-card.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.popup-custom_two .ant-modal-body,
.popup-custom_one .ant-modal-body {
  padding-top: 0;
}

.popup-custom_two .label,
.popup-custom_one .label {
  color: white;
  font-size: 14px;
}

.popup-custom_two .value,
.popup-custom_one .value {
  color: white;
  font-size: 14px;
}

.popup-custom_two .error,
.popup-custom_one .error {
  color: rgb(241, 0, 0);
  font-size: 14px;
}

.popup-custom_two .topup_credit,
.popup-custom_one .topup_credit {
  color: #7a00ff;
  font-size: 14px;
  text-decoration-line: underline;
  cursor: pointer;
}

.popup-custom_one .ant-modal {
  max-width: 370px;
  width: 90% !important;
}

.popup-login-error {
  max-width: 400px !important;
}

.ws-pre-wrap {
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.dashboard-sidebar-D {
  width: 220px;
  background-color: #26202e;
}

.dashboard-menu-M {
  background-color: #26202e;
  width: 100%;
  overflow-x: auto;
}

.dashboard-menu-M__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e2d1fe;
  padding: 12px;
  border-right: 1px solid #ffffff3b;
  min-width: 142px;
}

.dashboard-menu-M__item .container-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.dashboard-menu-M__item-tive {
  font-weight: bold;
  background-color: #3a2665;
}

.dashboard-content-D {
  width: calc(100% - 220px);
}

.dashboard_red_note {
  background-color: #b93f4e;
  border-radius: 5px;
}

.dashboard_red_note .left {
  width: max-content;
}

.dashboard_red_note .center {
  width: max-content;
  min-width: 155px;
  font-size: 16px;
  font-weight: bold;
}

.left-title {
  font-size: small;
  color: #e3d2ff;
}

.left-dash {
  min-height: calc(100vh - var(--min-height-header) - var(--min-height-footer));
}

.item-selected,
.formula:hover,
.left-dash-menu-item:hover {
  background-color: #3a2864 !important;
  color: #cfab61;
}

.list-formula {
  background-color: var(--bg-card-dashboard);
  height: 266px;
  overflow-y: auto;
}

.table-preiction .ant-table-tbody > tr.ant-table-row:hover > td,
.table-preiction .ant-table-tbody > tr > td.ant-table-cell-row-hove {
  background-color: transparent;
}

.table-prediction-container::-webkit-scrollbar,
.list-formula::-webkit-scrollbar,
.dashboard-menu-M::-webkit-scrollbar {
  width: 20px;
}

.list-table-dashboard::-webkit-scrollbar {
  height: 20px;
}

.table-prediction-container::-webkit-scrollbar-track,
.list-table-dashboard::-webkit-scrollbar-track,
.list-formula::-webkit-scrollbar-track,
.dashboard-menu-M::-webkit-scrollbar-track {
  background: #2e2838;
}

.table-prediction-container::-webkit-scrollbar-thumb,
.list-table-dashboard::-webkit-scrollbar-thumb,
.list-formula::-webkit-scrollbar-thumb,
.dashboard-menu-M::-webkit-scrollbar-thumb {
  background: #463c56;
  border-radius: 15px;
  border: 6px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.table-prediction-container::-webkit-scrollbar-thumb:hover,
.list-table-dashboard::-webkit-scrollbar-thumb:hover,
.list-formula::-webkit-scrollbar-thumb:hover,
.dashboard-menu-M::-webkit-scrollbar-thumb:hover {
  background: #735f95;
  border: 16px solid rgba(0, 0, 0, 0);
}

.list-formula .formula:nth-child(even) {
  background-color: var(--bg-formula-even);
}

.list-formula .formula:nth-child(odd) {
  background-color: var(--bg-formula-odd);
}

.dropdow_menu_header {
  background-color: #000000;
  min-width: 260px;
  width: 100%;
  color: white;
}

.dropdow_menu_header .ant-dropdown-menu-title-content {
  color: white;
}

.dropdow_menu_header .ant-dropdown-menu-item:hover {
  background-color: #000000;
}

.collapse-card-custom {
  background-color: var(--bg-card-dashboard);
  border-color: var(--bg-card-dashboard);
}

.dropdow_menu_header .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: transparent;
}
.hide-padding-collapse .ant-collapse-content-box {
  padding: 0;
}

.dropdow_menu_header .logout-container {
  border-top: 1px solid #bbbbbba8;
  padding-top: 16px;
}

/* .collapse-card-custom .ant-collapse-header {
  border-bottom: 2px solid #7a00ff;
} */

.collapse-card-custom .ant-collapse-header {
  border-bottom: 2px solid #380e62;
  padding-right: 16px !important;
  height: auto;
}

.collapse-card-custom .ant-collapse-header .ant-collapse-header-text {
  color: white;
  font-size: 18px;
}

.collapse-card-custom .ant-collapse-header .label-expan {
  color: #aeb3bf;
  font-size: 16px;
}

.collapse-card-custom .ant-collapse-content {
  background-color: var(--bg-card-dashboard);
  border-color: var(--bg-card-dashboard);
}

.collapse-card-custom .ant-collapse-item {
  border-color: var(--bg-card-dashboard);
}

.collapse-card-custom__open {
  background-color: transparent;
  border-color: transparent;
}

.collapse-card-custom__open .ant-collapse-header {
  border-bottom: transparent;
}

.collapse-card-custom__open .ant-collapse-content {
  background-color: transparent;
  border-color: transparent;
}

.collapse-card-custom__open .ant-collapse-item {
  border-color: transparent;
}

.collapse-language-header {
  background-color: transparent;
  border: none;
}

.collapse-language-header .ant-collapse-expand-icon {
  color: white;
}

.collapse-language-header > .ant-collapse-item {
  border-bottom: none;
}

.collapse-language-header .ant-collapse-content {
  border: none;
  background-color: #313435;
  color: white;
  font-size: 16px;
}

.collapse-language-header .ant-collapse-content-box {
  padding: 0;
}

.collapse-language-header .ant-collapse-header-text {
  color: white;
  font-size: 16px;
}

.formula-item {
  color: #e3d2ff;
}

.formula-item-selected {
  color: #cfab61;
}

.table-card {
  background-image: url('../assets/images/poker-cards-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: #30293b;
  border-radius: 5px;
  width: 120px;
}

.button-select-table-card {
  border-radius: 5px;
  width: 120px;
  height: auto;
  background-color: #321f57;
  border-color: #321f57;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
}

.button-select-table-card:hover,
.button-select-table-card:focus,
.button-select-table-card:active {
  background-color: #562fa1;
  border-color: #562fa1;
  color: white;
}

.button-select-table-card:hover {
  background-color: #562fa1;
  border-color: #562fa1;
  color: white;
}

.table-card-selected-bg,
.table-card:hover {
  background-color: #3a2864;
}

.font-medium {
  font-weight: 500;
}

.prediction-sec,
.prediction-result-table {
  padding: 12px 16px;
}

.card_title {
  color: #d5ad62;
  font-size: 16px;
  font-weight: 500;
}

.prediction-sec .hit-rate {
  font-size: 18px;
  color: #d9d9d9 !important;
}

.prediction-sec-bg {
  background-color: #261a42;
}

.prediction-circle {
  padding: 12px 0;
  border-radius: 5px;
}

.prediction-circle .name {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

.prediction-circle .hide-next-round {
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 500;
  color: #868686;
  text-align: center;
}

.prediction-circle .name-banker {
  color: #e50800;
}

.prediction-circle .name-player {
  color: #0cb9f2;
}

.prediction-circle .name-tie {
  color: #24bc00;
}

.prediction-circle .name-cal {
  color: #ff7d00;
}

.prediction-sec__statistic-item__banker .icon {
  width: 20px;
  height: 20px;
  background-color: #ca0200;
  border-radius: 50%;
  color: white;
  line-height: 20px;
  text-align: center;
}

.prediction-sec__statistic-item__player .icon {
  width: 20px;
  height: 20px;
  background-color: #0cb9f2;
  border-radius: 50%;
  color: white;
  line-height: 20px;
  text-align: center;
}

.prediction-sec__statistic-item__tie .icon {
  width: 20px;
  height: 20px;
  background-color: #24bc00;
  border-radius: 50%;
  color: white;
  line-height: 20px;
  text-align: center;
}

.table-prediction-container {
  max-height: 170px;
  overflow: auto;
}

.table-prediction .ant-table {
  background: transparent !important;
  color: white;
}

.table-prediction .ant-table-thead > tr > th {
  background-color: transparent !important;
  background: transparent !important;
  border: none;
  color: white;
  font-size: 16px;
}

.table-prediction .ant-table-tbody > tr > td {
  border-bottom: none;
  color: #bcbac0;
  font-size: 16px;
  font-weight: 500;
  padding-top: 7px;
  padding-bottom: 7px;
}

.table-prediction .ant-table-tbody > tr > td:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.table-prediction .ant-table-tbody > tr > td:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-prediction .ant-table-tbody .ant-table-row .td:nth-child(odd) {
  background-color: #3b2f55;
  border-radius: 30px;
}

.table-prediction .ant-table-tbody > tr:nth-child(odd):hover,
.table-prediction .ant-table-tbody > tr:nth-child(odd) {
  background-color: #3b2f55;
}

.table-prediction .ant-table-tbody > tr.ant-table-row:nth-child(even):hover,
.table-prediction .ant-table-tbody > tr:nth-child(even) {
  background-color: transparent;
}

.btn-setting,
.btn-view,
.btn-edit {
  outline: unset;
  border-radius: 8px;
  border: 0;
  color: #fff;
}

.btn-setting,
.btn-view {
  background: linear-gradient(180deg, #fa804d, #773920);
}

.btn-view:disabled,
.btn-setting:disabled {
  background: linear-gradient(180deg, #fa804d, #773920);
  color: white;
  opacity: 0.65;
}

.btn-view:active,
.btn-setting:active,
.btn-view:focus,
.btn-setting:focus {
  background: linear-gradient(180deg, #fa804d, #773920) !important;
  color: white !important;
}

.btn-view:hover,
.btn-setting:hover {
  background: linear-gradient(180deg, #fa8958, #944526) !important;
  color: white !important;
}

.btn-edit {
  background: linear-gradient(180deg, #b60e0e, #570400);
}

.btn-edit:disabled {
  background: linear-gradient(180deg, #b60e0e, #570400);
  color: white;
  opacity: 0.65;
}

.btn-edit:focus,
.btn-edit:active {
  background: linear-gradient(180deg, #b60e0e, #570400);
  color: white !important;
}

.btn-edit:hover {
  background: linear-gradient(180deg, #ce1d1d, #690a05);
  color: white !important;
}

.admin-card-cpn {
  background-color: #1c1c1c;
  border-top: 1px solid #96532c;
}

.member-infor .member-infor__title {
  color: #cebe9f;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.btn-click-event {
  color: #e66e3d;
  font-weight: bold;
  cursor: pointer;
}

.btn-click-event:hover {
  text-decoration: underline;
}

.member-infor__table-row .right {
  color: white;
  font-weight: 400;
}

.member-infor__table-col {
  border: 1px solid #aa5a34;
  text-align: center;
  padding: 4px 8px;
  color: white;
  font-weight: bold;
  word-wrap: break-word;
}

.btn-actions {
  border: none;
  border-radius: 8px;
  font-weight: 500;
  color: white;
}

.btn-actions__back {
  background: linear-gradient(180deg, #b60e0e, #570400);
  transition: none;
}

.btn-actions__edit {
  background: linear-gradient(180deg, #fa804d, #773920);
  transition: none;
}

.btn-actions__reset-password,
.btn-actions__add-credit {
  background: linear-gradient(180deg, #3e8acc, #0088ff);
  transition: none;
}

.btn-actions__back:hover,
.btn-actions__back:focus,
.btn-actions__back:active {
  text-decoration: none;
  background: #aa0d0c !important;
  color: white;
}

.btn-actions__edit:hover,
.btn-actions__edit:focus,
.btn-actions__edit:active {
  text-decoration: none;
  background: #c16239;
  color: white;
}

.btn-actions__add-credit:hover,
.btn-actions__add-credit:focus,
.btn-actions__add-credit:active,
.btn-actions__reset-password:hover,
.btn-actions__reset-password:focus,
.btn-actions__reset-password:active {
  text-decoration: none;
  background: #3497ee;
  color: white;
}

.update-member-container .title-page {
  color: white;
  font-size: 22px;
  margin-bottom: 0;
}

.update-member-container .title {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
}

.update-member-container .value {
  color: white;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 300;
}

.update-member-container .activated-item {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.error-addmin-msg {
  font-size: 24px;
  color: #c16239;
  font-weight: bold;
}

.table-admin-access .ant-table,
.table-admin-access .ant-table-thead > tr > th,
.table-admin-access .ant-table-tbody > tr {
  background: transparent;
}

.table-admin-access .ant-table-thead > tr > th {
  color: white;
  font-size: 18px;
  font-weight: bold;
  border-top: 1px solid white;
}

.table-admin-access .ant-table-tbody > tr.ant-table-row:hover > td,
.table-admin-access
  .ant-table-tbody
  .ant-table-tbody
  > tr
  > td.ant-table-cell-row-hover {
  background: transparent;
}

.table-admin-access .ant-table-tbody > tr > td {
  border-bottom: none;
}

.table-admin-access .ant-pagination-prev button,
.table-admin-access .ant-pagination-next button {
  background: linear-gradient(180deg, #fa804d, #773920);
  outline: unset;
  border-radius: 8px;
  border: 0;
  color: #fff;
  height: auto;
}

.note-credit {
  top: 0;
  right: -40px;
  background-color: #b93f4e;
  padding: 6px;
  border-radius: 12px;
  width: max-content;
}
.note-credit-popover .ant-popover-inner {
  background-color: #b93f4e;
  border-radius: 8px;
  min-width: 180px;
}

.note-credit-content {
  font-size: 15px;
  max-width: 200px;
}

.note-credit-popover .ant-popover-arrow-content:before {
  background-color: #b93f4e;
}

.note-credit-popover .ant-popover-arrow {
  right: 4px;
}

.g-recaptcha {
  transform: scale(125%);
  transform-origin: 0 0;
  height: 100px;
}

.banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-image {
  width: 100%;
  max-width: 960px;
  cursor: pointer;
}

.line-chart > canvas {
  height: 100% !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: white;
  background-color: transparent;
  opacity: 0.8;
  cursor: not-allowed;
}

.dropdown-user-manager {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown-user-manager .label {
  width: max-content;
}

.dropdown-user-manager__overlay {
  padding: 0;
  background-color: #303435;
  border-radius: 8px;
  border: 2px solid #484848;
  margin-top: 8px;
}
.dropdown-user-manager__overlay .ant-dropdown-menu-item {
  padding: 10px 18px;
  color: white;
}
.dropdown-user-manager__overlay .ant-dropdown-menu-item:hover {
  background-color: #555a5a;
}
.dropdown-user-manager__overlay .ant-dropdown-menu-item:first-child {
  border-bottom: 2px solid #484848;
}

.cardTypeTwo .ant-collapse-header-text {
  max-width: 50%;
}

.modal-validate-phone-number .ant-modal-title {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .cardTypeTwo .ant-collapse-header-text {
    max-width: auto;
  }
}

@media (max-width: 767px) {
  .cardTypeTwo .ant-collapse-content-active {
    background-color: var(--bg-card-dashboard);
  }

  .main-login-box {
    padding-top: 0;
  }

  .main-login-box > .col-high > .left-style {
    background-image: none;
    background-size: auto;
    background-repeat: no-repeat;
    padding: 20px 35px;
  }

  .inner-left,
  .inner-right {
    margin: 0 auto;
  }

  .container-form-auth {
    padding: 20px 35px;
    background-color: #0a0013;
  }

  .btn-custom-Line {
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .lobby-body-bg {
    background-image: none;
  }

  .dashboard-content-D {
    width: 100%;
  }

  .table-admin-access .ant-table-thead > tr > th {
    font-size: 16px;
  }

  .g-recaptcha {
    transform: scale(100%);
    transform-origin: 0 0;
  }
}

/* start sort-search style*/

.filter-container {
  height: 35px;
}

.search-text-color,
.search-text-color::placeholder {
  color: var(--filter-form-text-color);
}

.form-input_select-container {
  border: none;
  background-color: transparent;
  color: var(--filter-form-text-color);
}

.form-input_select-container > .ant-select-arrow {
  color: var(--filter-form-arrow-color);
}

.form-input_select-container.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  box-shadow: none;
}

.form-input_select-container > .ant-select-selector .ant-select-selection-item {
  padding: 0 20px 0 0;
}

.form-input_select-container__dropdown {
  min-width: 175px !important;
}

.search-field {
  background-color: var(--filter-form-search-field-color);
  border-color: var(--filter-form-search-field-color);
  position: relative;
  align-items: center;
}

.input-search,
.input-search:hover,
.input-search:focus {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding: 0 30px 0 10px;
  z-index: 2;
}

.icon-search {
  z-index: 1;
  width: 20px;
  position: absolute;
  right: 10px;
}

/* end sort-search style*/

.mh-80 {
  min-height: 80px;
}
.ant-select-arrow > .anticon-down {
  display: none;
}

/* mobile style*/
@media (max-width: 415px) {
  .prediction-circle {
    padding: 10px 0;
  }

  .prediction-circle .icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }

  .prediction-circle .name {
    font-size: 14px;
  }

  .prediction-sec__statistic-item__banker .icon,
  .prediction-sec__statistic-item__player .icon,
  .prediction-sec__statistic-item__tie .icon {
    width: 13px;
    height: 13px;
    line-height: 13px;
    font-size: 11px;
  }

  .prediction-sec__statistic-item__banker .value,
  .prediction-sec__statistic-item__player .value,
  .prediction-sec__statistic-item__tie .value {
    font-size: 11px;
  }

  .prediction-sec .hit-rate {
    font-size: 12px;
  }

  .g-recaptcha {
    transform: scale(80%);
    transform-origin: 0 0;
  }

  .popup-login-error {
    max-width: 286px !important;
  }
}

@media (min-width: 768px) {
  .note-credit-content_mobile {
    display: none;
  }

  .hide-for-desktop {
    display: none;
  }
}
